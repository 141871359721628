import clsx from 'clsx';

import Markdown from 'components/common/Markdown';
import { MarkdownWithBulletProps } from 'interfaces/cms/content';

function MarkdownWithBullet({
  bulletColor,
  subtitle,
  align,
  subtitleColor,
  cssClass = '',
}: MarkdownWithBulletProps) {
  return (
    <div
      className={clsx(
        'heart-bullet',
        bulletColor ?? bulletColor,
        cssClass ?? cssClass
      )}
    >
      <Markdown md={subtitle} textAlign={align} textColor={subtitleColor} />
    </div>
  );
}

export default MarkdownWithBullet;
