import clsx from 'clsx';

import Link from 'components/common/Link';
import Image from 'components/common/Image';
import Button from 'components/common/Button';
import Markdown from 'components/common/Markdown';
import MarkdownWithBullet from 'components/common/MarkdownWithBullet';

import { ButtonProps } from 'interfaces/cms/common';
import styles from './SingleTextBox.module.scss';

export interface SingleTextBoxProps {
  title: string;
  subtitle: string;
  icon: any;
  titleColor: string;
  subtitleColor: string;
  align: React.CSSProperties['textAlign'];
  button: ButtonProps | null;
  bulletColor: string;
}

function SingleTextBox({
  title,
  subtitle,
  icon,
  titleColor,
  subtitleColor,
  align,
  button,
  bulletColor,
}: Readonly<SingleTextBoxProps>) {
  const contentAlign = {
    left: 'justify-start',
    center: 'justify-center',
    right: 'justify-end',
  } as Record<string, string>;

  return (
    <div className={clsx('mt-[1.875rem] flex', align && contentAlign[align])}>
      {icon && (
        <div className="relative mr-[0.75rem] max-w-[1.625rem] max-h-[1.625rem]">
          <Image
            src={icon.url}
            alt={icon.alternativeText}
            width="26"
            height="26"
            sizes="100vw"
          />
        </div>
      )}

      <div>
        <Markdown
          md={title}
          textAlign={align}
          textColor={titleColor}
          className={styles.textBox__title}
        />
        <MarkdownWithBullet
          bulletColor={bulletColor}
          subtitle={subtitle}
          align={align}
          subtitleColor={subtitleColor}
          cssClass={clsx('mt-[0.4375rem]', styles.textBox__text)}
        />
        {(button?.buttonUrl || button?.buttonPageSlug?.slug) && (
          <>
            <div className="mt-[0.9375rem]" />
            <Link
              href={button?.buttonPageSlug?.slug || button?.buttonUrl}
              rel={button?.buttonLinkRel}
              isExternalLink={!!button?.buttonUrl}
              className="flex justify-center"
            >
              <Button {...button} />
            </Link>
            {button?.disclaimer && (
              <Markdown
                textAlign={align}
                md={button.disclaimer.title || ''}
                textColor={button.disclaimer.color || 'text-textColor'}
                hasLockIcon={button.disclaimer.lockIcon}
                className="mt-[0.625rem]"
              />
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default SingleTextBox;
