import clsx from 'clsx';

import Link from 'components/common/Link';
import Button from 'components/common/Button';
import Markdown from 'components/common/Markdown';
import ParagraphHeader from 'components/ParagraphHeader';

import { TextBoxProps } from 'interfaces/cms/content';
import SingleTextBox from './SingleTextBox';

function TextBox({ bg, header, rows }: TextBoxProps) {
  const gridCols = {
    1: 'lg:grid-cols-1',
    2: 'lg:grid-cols-2',
    3: 'lg:grid-cols-3',
    4: 'lg:grid-cols-4',
    5: 'lg:grid-cols-5',
    6: 'lg:grid-cols-6',
    7: 'lg:grid-cols-7',
    8: 'lg:grid-cols-8',
    9: 'lg:grid-cols-9',
    10: 'lg:grid-cols-10',
    11: 'lg:grid-cols-11',
    12: 'lg:grid-cols-12',
  } as Record<number, string>;

  return (
    <div
      className="py-[1.875rem] px-[0.9375rem] md:px-[1.25rem]"
      style={{ backgroundColor: bg }}
    >
      {header && <ParagraphHeader {...header} />}

      <div className="max-w-[69.375rem] mx-auto">
        {rows?.map((row) => (
          <div key={row.id}>
            <div
              className={clsx(
                'grid grid-cols-1 gap-[1.875rem]',
                gridCols[row.columns]
              )}
            >
              {row.textBoxes?.map((singleTextBox) => (
                <SingleTextBox key={singleTextBox.id} {...singleTextBox} />
              ))}
            </div>

            {row.remark && (
              <Markdown
                textAlign="left"
                md={row.remark}
                textColor="#4b4b4b"
                className={clsx('mt-[1.85rem]')}
              />
            )}

            {(row?.rowButton?.buttonUrl || row?.rowButton?.buttonPageSlug) && (
              <div className="mt-[1.375rem] mb-[3.75rem] last:mb-0">
                <div className="text-center mx-auto w-full md:w-[31.25rem]">
                  <Link
                    href={
                      row?.rowButton?.buttonPageSlug?.slug ||
                      row?.rowButton?.buttonUrl
                    }
                    rel={row?.rowButton?.buttonLinkRel}
                    isExternalLink={!!row.rowButton.buttonUrl}
                  >
                    <Button
                      {...row.rowButton}
                      buttonType="primary"
                      buttonSize="normal"
                      isRowButton
                    />
                  </Link>
                </div>
                {row?.rowButton?.disclaimer && (
                  <Markdown
                    textAlign="center"
                    md={row.rowButton.disclaimer.title || ''}
                    textColor={
                      row.rowButton.disclaimer.color || 'text-textColor'
                    }
                    hasLockIcon={row.rowButton.disclaimer.lockIcon}
                    className="mt-[0.625rem]"
                  />
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default TextBox;
